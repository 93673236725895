import React from "react";
import styled from "styled-components";
import CircleText from "../poster/CircleText";

const Container = styled.div`
  display: flex;
  position: relative;
  color: ${p => p.color};
  align-items: center;
  justify-content: center;

  @media (max-width: 600px) {
    flex-wrap: wrap;
    justify-content: center;
  }

  blockquote {
    display: block;
    margin: 0px;
    padding: 0px;
    margin-left: 20px;
    margin-top: -20px;
    background-color: ${p => p.color};
    padding: 6px 10px;
    max-width: 500px;
    border-radius: 18px;
    color: ${p => p.backgroundColor};
  }

  div h3 {
    margin: 0px;
    text-align: center;
    font-size: 1em;
  }
`;

const AvatarContainer = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  position: relative;
  width: ${p => p.size + 42}px;
  height: ${p => p.size + 42}px;
  margin-bottom: 10px;
  color: ${p => p.color};
`;

const Avatar = styled.img`
  border: 1px solid ${p => p.borderColor};

  border-radius: 50%;
  width: ${p => p.size}px;
  height: ${p => p.size}px;
`;

const Testimonial = ({
  image,
  quote,
  color,
  backgroundColor,
  link,
  name,
  borderColor
}) => {
  return (
    <Container color={color} backgroundColor={backgroundColor}>
      <AvatarContainer color={color} size={80} href={link} target="_blank">
        <CircleText
          scale={1}
          text={name}
          fontSize={12}
          radius={40}
          color={borderColor}
        />
        <Avatar
          alt="testimonial avatar"
          borderColor={borderColor}
          size={80}
          src={image}
        />
      </AvatarContainer>
      <blockquote>&ldquo;{quote}&rdquo;</blockquote>
    </Container>
  );
};

export default Testimonial;
