import React from "react";
import styled from "styled-components";
import { POSTER_PRICE } from "../../constants";
import * as Icon from "react-feather";

const features = [
  {
    icon: "Edit",
    title: "Designed by you.",
    description:
      "Create your map print to showcase any place just how you want it to look in minutes."
  },
  {
    icon: "ThumbsUp",
    title: "Museum quality.",
    description:
      "Printed on durable matte paper with each line precisely rendered in vibrant color."
  },
  {
    icon: "CreditCard",
    title: `Starting at $${POSTER_PRICE}.`,
    description:
      "With free shipping & a money back guarantee, your TiltMap costs less than... most things."
  }
];

const FeaturesContainer = styled.div`
  position: relative;
  z-index: 2;
  display: flex;
  flex-wrap: wrap;
  /* max-width: 1200px; */
  margin: 40px auto;
  justify-content: center;
`;

const FeatureContainer = styled.div`
  position: relative;
  color: ${p => p.foregroundColor};
  text-align: center;
  z-index: 20;
  padding: 20px 40px;
  margin-right: 20px;
  width: calc(33% - 20px);

  @media (max-width: 600px) {
    width: 80%;
    margin-bottom: 20px;
    margin-right: 0px;
  }

  h1,
  p {
    position: relative;
    z-index: 20;
    color: ${p => p.foregroundColor};
  }

  h1 {
    font-family: "Gotham Pro";
    font-weight: 900;
    font-size: 1.4em;
  }

  &::after {
    display: block;
    position: absolute;
    left: 0px;
    top: 0px;
    content: "";
    width: 100%;
    height: 100%;
    color: ${p => p.foregroundColor};
    background-color: ${p => p.backgroundColor};
    border: 3px solid ${p => p.color};
    border-radius: 6px;

    transform: skew(${-8}deg);
    //box-shadow: rgba(0, 0, 0, 0.1) 4px 6px 10px, rgba(0, 0, 0, 0.2) 4px 3px 6px;
  }
`;
const Feature = ({ title, description, ...props }) => {
  const IconComponent = Icon[props.icon] || null;
  return (
    <FeatureContainer {...props}>
      <IconComponent size={30} style={{ zIndex: 2, position: "relative" }} />
      <h1>{title}</h1>
      <p>{description}</p>
    </FeatureContainer>
  );
};

const Features = props => {
  return (
    <FeaturesContainer {...props}>
      {features.map((feature, i) => {
        return <Feature key={i} {...feature} {...props} />;
      })}
    </FeaturesContainer>
  );
};

export default Features;
