import React from "react"
import Layout from "../components/layout"
import Intro from "../components/promo/Intro"

const styles = theme => ({
  root: {
    textAlign: "center",
    paddingTop: theme.spacing.unit * 20
  }
})

const IndexPage = props => {
  return (
    <Layout>
      <Intro {...props} />
    </Layout>
  )
}

export default IndexPage
