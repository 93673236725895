import React from "react"
import styled from "styled-components"

const unit = (u, un = "px") => (typeof u === "number" ? `${u}${un}` : u)

const Stripe = styled.div`
  position: absolute;
  top: ${p => unit(p.top)};
  left: ${p => unit(p.left + p.rotation * 20)};
  width: calc(100% + ${p => Math.abs(p.rotation) * 100}px);
  height: ${p => unit(p.height)};
  transform: rotate(${p => p.rotation}deg);
  background-color: ${p => p.backgroundColor};
  opacity: 1;
  border-top: 1px solid ${p => p.borderColor};
  border-bottom: 1px solid ${p => p.borderColor};
  z-index: 1;
`

Stripe.defaultProps = {
  rotation: -10,
  height: 520,
  top: 0,
  left: 0
}

export default Stripe
