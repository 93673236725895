import React, { Component, useState, useEffect } from "react"
import styled from "styled-components"
import Typist from "react-typist"

// const prompt = "Where were you when you"

// const headlines = [
//   "found your people?",
//   "fell in love?",
//   "grew up?",
//   "said “I do”?",
//   "learned Santa was a lie?",
//   "had your mind blown?",
//   "shared that 1st kiss?",
//   "stood your ground?",
//   "first saw your child?",
//   //"first saw your child 💩?",
//   "entered the world?",
//   //  "took the road < traveled?",
//   "found your place?",
//   "said “yes”?"
//   //"said “no”?"
// ]

const defaultHeadlines = [
  "any place on earth",
  "your favorite city",
  "where you fell in love",
  "where you live",
  `where you said “yes”`,
  "where you work",
  "where you graduated",
  "where you said “I do”",
  "where you were born"
]

const Heading = styled.h1`
  font-family: "Gotham Pro";
  font-weight: 900;
  font-size: 2.5em;
  /* line-height: 54px; */

  @media (max-width: 1000px) {
    font-size: 2em;
  }

  @media (max-width: 800px) {
    text-align: center;
  }
  @media (max-width: 600px) {
    font-size: 1.5em;
  }
`

const Headline = ({ color, headlines, onCycle, intervalMS }) => {
  const [index, setIndex] = useState(0)
  const headline = headlines[index]

  const nextHeadline = () => {
    const nextIndex = index < headlines.length - 1 ? index + 1 : 0
    setIndex(nextIndex)
    if (onCycle) onCycle(nextIndex)
    // setTimeout(() => {
    //   setIndex(index < headlines.length - 1 ? index + 1 : 0)
    // }, intervalMS)
  }

  return (
    <Heading color={color}>
      Create a map poster&nbsp;of{" "}
      <Typist
        style={{ borderBottom: "1px solid #fff" }}
        onTypingDone={nextHeadline}
        key={headline}
      >
        <u>{headline}</u>
        <Typist.Delay ms={intervalMS} />
      </Typist>
    </Heading>
  )
}

Headline.defaultProps = {
  headlines: defaultHeadlines,
  intervalMS: 2000
}

export default Headline
