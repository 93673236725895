import React from "react"
import styled from "styled-components"
import * as Icon from "react-feather"
import { Tooltip } from "@material-ui/core"

const MessageContainer = styled.div`
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  color: #fff;
  font-size: 16px;
  background-color: ${p => p.backgroundColor || p.theme.palette.text.primary};
  color: ${p => p.foregroundColor || p.theme.palette.background.paper};
  border-bottom: 1px solid ${p => p.foregroundColor};
`

const Message = ({ foregroundColor, backgroundColor }) => {
  return null

  return (
    <MessageContainer
      foregroundColor={foregroundColor}
      backgroundColor={backgroundColor}
    >
      <Tooltip title="Estimate based USA domestic standard shipping">
        <div style={{ display: "flex", alignItems: "center" }}>
          <Icon.Heart size={18} style={{ marginRight: 10 }} />
          <span>Order by february 4th to be on time for Valentine's Day!*</span>
        </div>
      </Tooltip>
    </MessageContainer>
  )
}

export default Message
