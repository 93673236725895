export default [
  {
    image: require("../../assets/chris-s.png"),
    link: "http://shiflett.org/",
    quote:
      "TiltMaps is great. Nothing can elicit that sense of place quite like a map of somewhere familiar.",
    name: "Chris S."
  },
  {
    image: require("../../assets/john-w.png"),
    link: "http://artillerymedia.com/",
    quote:
      "We love our TiltMap! Folks love the personal touch of the map being our town.  Also the classy design fits perfectly in our creative space.",
    name: "John W."
  },
  {
    image: require("../../assets/paul-s.png"),
    link: "https://pauladamsmith.com",
    quote: `TiltMaps ​made it incredibly easy to create a unique,
    high-quality, printed poster featuring ​a​ place ​that is
    meaningful to me​. The interface ​was​ simple to use, and
    ​I​​​​​​ ​could​ see in real-time exactly what ​my​ poster
    ​was ​going to look like as ​I​ ma​de​​​​
    ​​c​​h​​a​​n​​g​​e​​s​​.​​ ​​M​​y​​ ​​p​​r​​i​​n​​t​​
    ​​a​​r​​r​​i​​v​​e​​d​​ ​​q​​u​​i​​c​​k​​l​​y​​,
    ​​​​a​​​​n​​d​ it​​​​​​​​​ looks terrific. A clever and
    friendly service.`,
    name: "Paul S."
  }
]
