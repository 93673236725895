import React from "react"
import { POSTER_PRICE } from "../../constants"

export const coreFAQs = [
  {
    question: "Why maps?",
    answer: () => (
      <div>
        <p>
          "Where were you when ______?" is a go-to question we ask about
          life-changing events because our{" "}
          <a
            href="https://www.theatlantic.com/technology/archive/2014/08/in-the-brain-memories-are-inextricably-tied-to-place/375969/"
            target="_blank"
          >
            most important memories are hardwired to places
          </a>
          . An artfully printed map of a significant place offers a uniquely
          beautiful window into a prized memory or story you want to remember
          and start conversations about.
        </p>
        <p>
          And as the maps we use every day to get from place to place become
          more digital and transient, a TiltMap recovers the simple beauty of
          printed maps you can hold in your hand, give to a friend, or frame on
          your wall.
        </p>
      </div>
    )
  },
  {
    question: "Why the tilt?",
    answer:
      "We could say that our subtly tilted, patented, perfect 45 degree angle gives the viewer a fresh, dynamic perspective on a beloved location. (But it’s really just because that tilt looks so dang cool.)"
  },
  {
    question: "How much does a TiltMap cost?",
    answer: `Your custom TiltMap starts at $${POSTER_PRICE} dollars. We hope it’s the best $${POSTER_PRICE} you’ve spent this year. In fact, if you don’t love your TiltMap, we’ll refund your money in full.`
  },
  {
    question: "When will I get my map?",
    answer:
      "You get FREE shipping anywhere in the USA or Canada and your TiltMap will arrive anywhere between 5 and 10 business days after fulfilment."
  }
]

export const giftFAQs = [
  {
    question: "How do I print the Gift Card?",
    answer:
      "Once you've purchased your gift card, you will be given a link to a ready-to-print gift card."
  }
]
