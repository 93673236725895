import React from "react"
import styled from "styled-components"
import { POSTER_PRICE } from "../../constants"

const FAQsContainer = styled.div`
  position: relative;
  z-index: 2;
  max-width: 1000px;
  margin: 40px auto;
`

const FAQContainer = styled.div`
  position: relative;
  color: ${p => p.foregroundColor};
  z-index: 2;

  a {
    color: ${p => p.foregroundColor};
  }

  h1 {
    margin: 0px;
    font-size: 1.4em;
  }
`
const FAQ = ({ question, answer, ...rest }) => {
  return (
    <FAQsContainer {...rest}>
      <h1>{question}</h1>
      <div>{typeof answer === "function" ? answer() : answer}</div>
    </FAQsContainer>
  )
}

const FAQs = ({ faqs, ...props }) => {
  return (
    <FAQContainer {...props}>
      {faqs.map((faq, i) => {
        return <FAQ key={i} {...faq} {...props} />
      })}
      <div style={{ textAlign: "center" }}>
        More questions?{" "}
        <a
          href="mailto: mark@tiltmaps.com"
          style={{ color: props.foregroundColor }}
        >
          Contact us.
        </a>
      </div>
    </FAQContainer>
  )
}

export default FAQs
