import React from "react";
import Testimonial from "./Testimonial";

import testimonials from "../content/testimonials";

const Testimonials = ({ foregroundColor, backgroundColor }) => {
  return (
    <div>
      {testimonials.map((testimonial, i) => {
        return (
          <Testimonial
            key={i}
            backgroundColor={backgroundColor}
            borderColor={foregroundColor}
            color={foregroundColor}
            {...testimonial}
          />
        );
      })}
    </div>
  );
};

export default Testimonials;
